import { CSSProperties } from "react";


export function NextPrevButton(props:{onClick:()=>void, label:string, srcNext?:string, srcPrev?:string, visible:boolean}){
    const { label, srcNext, srcPrev, onClick, visible } = props;

    const style:CSSProperties = {
        marginLeft: 10,
        marginRight: 10,
        cursor: 'pointer',
        color: 'var(--color-white)',
        display: 'flex',
        alignItems: 'center',
        fontFamily: "AppFontBold",
        visibility: visible ? 'visible' : 'hidden',
    }
    const iconStyle:CSSProperties = {
        marginLeft: 30,
        marginRight: 30,
        position: 'relative',
        top: 0,
    }
    const labelStyle:CSSProperties = {
        fontSize: 30,
    }
    return (
        <div className="carousel-next-button" style={style} onClick={onClick}>
            { srcPrev && <img style={iconStyle} src={srcPrev} /> }
            <div style={labelStyle}>{label}</div>
            { srcNext && <img style={iconStyle} src={srcNext} /> }
        </div>
    )
}
