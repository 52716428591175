import { CSSProperties, ReactNode } from "react";
import { BarLabel } from "./BarLabel";


export function BarButton(props:{disabled?:boolean, on:string, off:string, id:string, selection:string, labelText?:ReactNode, onClick:(selection:string)=>void}){
    const {disabled, on, off, id, selection, labelText, onClick} = props;
    const selected = id === selection;
    const handleClick = () => onClick(id);

    const style:CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 14,
        marginRight: 14,
        cursor: 'pointer',
        pointerEvents: disabled ? 'none' : 'all',
        opacity: disabled ? 0.5 : 1.0,
    }

    return (
        <div className="bar-button" onClick={handleClick} style={style}>
            { selected && <img src={off} /> }
            { !selected && <img src={on} /> }
            { labelText && <BarLabel text={labelText} selected={selected} /> }
        </div>
    )
}
