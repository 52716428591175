import * as React from 'react';
import { useEffect, useState } from 'react';
import useRaf from "@rooks/use-raf";
import { appContext } from '../App';
import { CSSProperties } from 'react';

type Props = {
    children: React.ReactNode
}


export default function ({ children }: Props) {
    const [scale, setScale] = useState(1)
    const [app, dispatch] = React.useContext(appContext);
    const isIos = app.config.isIos;
    const aspectRatio = app.config.aspectRatio;
    const width = app.config.width;
    const height = app.config.height;

    useRaf(() => {
        if(isIos && aspectRatio > 2) return; // Bail out if keyboard is showing
        setScale(getScale(app.config.width, app.config.height)); 
    }, true)

    useEffect(function () {
        setWindowScale(scale, width, height);
    }, [scale, width, height])

    const style:CSSProperties = {
        width: 'var(--width)',
        height: 'var(--height)',
        display: 'flex',
        transformOrigin: 'top left',
        transform: 'scale(var(--scale)) translate(var(--offset-x), var(--offset-y))',
        backgroundImage: `url(${require('../assets/images/interface/bkg--menu.png').default})`,
        backgroundColor: 'gray',
        overflow: 'hidden',
        overflowX: 'hidden',
        position: 'relative',
    }

    return (
        <div className="ScaleContainer" style={style}>
            { children}
        </div>
    )
}


export function getScale(width:number, height:number){
    return Math.min(window.innerWidth / width, window.innerHeight / height);
}


function setWindowScale(scale:number, width:number, height:number){
    const offsetX = ((window.innerWidth / scale) - width) / 2;
    const offsetY = ((window.innerHeight / scale) - height) / 2;
    document.body.style.setProperty('--width', `${width}px`);
    document.body.style.setProperty('--half-width', `${width/2}px`);
    document.body.style.setProperty('--half-height', `${height/2}px`);
    document.body.style.setProperty('--height', `${height}px`);
    document.body.style.setProperty('--scale', `${scale}`);
    document.body.style.setProperty('--offset-x', `${offsetX}px`);
    document.body.style.setProperty('--offset-y', `${offsetY}px`);
}
