import { CSSProperties } from "react";
import { useContext } from "react";
import { appContext } from "../App";
import { MoviePlayer } from "./MoviePlayer";


export function GalleryMovieItem(props:{src:string, index:number, autoplay:boolean}){
    const {src, autoplay} = props;
    const [app, dispatch] = useContext(appContext);
    
    const style:CSSProperties = {
        width: 'var(--width)',
        height: 'var(--height)',
    }
    return (
        <div style={style}>
            <MoviePlayer src={src} autoPlay={autoplay} showPreview={true} offsetY={app.config.headerHeight+320}/>
        </div>
    )
}