import { useEffect } from "react";
import { CSSProperties } from "react";
import { useRef } from "react";
import { useContext } from "react"
import { appContext } from "../App"

export type MoviePlayerState = typeof moviePlayerStateInitial;
export type MoviePlayerAction
    = { id: "movie.play" }
    | { id: "movie.stop" }
    | { id: "movie.toggle" }


export const moviePlayerStateInitial = {
    playing: false,
}


export function moviePlayerReducer(state:MoviePlayerState, action:MoviePlayerAction):MoviePlayerState {
    switch (action.id) {
        case "movie.play": return { ...state, playing:true };
        case "movie.stop": return { ...state, playing:false };
        case "movie.toggle": return { ...state, playing:!state.playing };
    }
}


type Props = {
    src: string,
    autoPlay?:boolean,
    offsetY?:number,
    showPreview?:boolean,
    preload?:boolean,
    dimensions?:{
        width:number,
        height:number,
    }
}


export function MoviePlayer(props:Props){
    const {src, autoPlay, offsetY, showPreview, preload, dimensions} = props;
    const [app, dispatch] = useContext(appContext);
    const {playing} = app.moviePlayer;

    const toggle = (playing:boolean) => {
        if(playing) dispatch({ id:'movie.stop'})
        else dispatch({ id:'movie.play'})
        //dispatch({ id:'movie.toggle'})
    }

    useEffect(() => {
        dispatch({ id:'movie.stop'})
    }, [dispatch, app.carousel.index, app.carousel.show]);

    const style = {
        cursor: 'pointer',
    }

    return (
        <div style={style}>
            <Video preload={preload} src={src} dimensions={dimensions} playing={playing} autoPlay={!!autoPlay} onClick={toggle} offsetY={offsetY} showPreview={showPreview}/>
            <Background playing={playing}/>
        </div>
    )
}

function Background(props:{playing:boolean}){
    const [app, dispatch] = useContext(appContext);
    const {playing} = props;

    const style:CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 'var(--width)',
        height: 'var(--height)',
        backgroundColor: '#000000',
        opacity: playing ? 1.0 : 0.0,
        transition: 'opacity 0.5s',
        pointerEvents: 'none',
        zIndex: 0,
    }

    return (
        <div style={style}></div>
    )
}


function Video(props:{src:string, dimensions?:{width:number,height:number}, preload?:boolean, playing:boolean, autoPlay:boolean, offsetY?:number, showPreview?:boolean, onClick:(playing:boolean)=>void}){
    const [app, dispatch] = useContext(appContext);
    const {src, playing, autoPlay, showPreview, onClick, preload, offsetY, dimensions} = props;
    const ref = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if(ref.current){
            if(ref.current.paused===false) ref.current.pause();
            //if(playing) ref.current.play();
            //else ref.current.pause();
            //if(ref.current.paused==false) ref.current.pause();
        }
    }, [ref, ref.current?.paused, playing]);
    

    function onVideoClick(){
        console.log("Video click");
        if(ref.current){
            /*
            if(ref.current.paused){
                console.log("Play")
                ref.current.play();
                //onClick(true);
            }else{
                ref.current.pause();
                console.log("Pause")
                ref.current.currentTime = 0;
                //onClick(false);
            }
            */
        }
        
    }

    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: app.config.headerHeight + (offsetY||0),
        width: dimensions?.width,
        height: dimensions?.height,
    }

    const videoStyle = {
        width: '25%',
        height: 'auto',
        transform: 'scale(4, 4)',
        //filter: showPreview||playing ? 'none' : 'brightness(0)',
        transition: 'filter 0.5s',
        zIndex: app.config.zIndex.video,
        backgroundColor: 'black',
        //height: 'calc(var(--height) - 400px)'
    }

 
    return (
        <div style={style}>
            {/*<PlayButton playing={playing}/>*/}
            <video key={src} onClick={()=> onVideoClick()} controls={true} controlsList="nodownload" ref={ref} style={videoStyle} autoPlay={autoPlay} preload={(preload||autoPlay)?'auto':'metadata'}>
                <source src={src} type='video/mp4' />
            </video>
        </div>
    )
}


function PlayButton(props:{playing:Boolean}){
    const [app, dispatch] = useContext(appContext);
    const {playing} = props;
    const style:CSSProperties = {
        position: 'absolute',
        display: playing ? 'none' : 'block',
        zIndex: app.config.zIndex.video+1,
        pointerEvents: 'none',
    }

    return <img style={style} src={require('../assets/images/interface/icon--video.png').default} />
}
