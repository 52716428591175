import {CSSProperties, ReactNode} from 'react'


export function LayerSwitch(props:{selection:string, layers:Record<string, ReactNode>}){
    const {selection, layers} = props;
    const opacityFor = (id:string) => ({
        opacity: id===selection ? 1.0 : 0,
        transition: 'opacity 0.5s',
        pointerEvents: id===selection ? 'all' : 'none',
    } as CSSProperties)

    return (
        <div>
            {
                Object.keys(layers).map(id => <div style={opacityFor(id)} key={id}>{layers[id]}</div>)
            }
        </div>
    )
}
