import { ReactNode, useState } from 'react';
import { BarBreak } from '../components/BarBreak';
import { BarButton } from '../components/BarButton';
import { BarDropdown } from '../components/BarDropdown';
import { BarDropdownItem } from '../components/BarDropdownItem';
import { BarLabel } from '../components/BarLabel';
import { BottomBar } from '../components/BottomBar';
import { FullView } from '../components/FullView';
import HotspotItem from '../components/HotspotItem';
import HotSpots from '../components/HotSpots';
import { Layer } from '../components/Layer';
import { LayerContainer } from '../components/LayerContainer';
import { LayerSwitch } from '../components/LayerSwitch';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { Page } from '../components/Page'
import { ZoomPinch } from '../components/ZoomPinch';


const ID_BURRIAL_INFO = "burrial_info"
const ID_TRAIN = "train"
const ID_BICYLE = "bicycle"
const ID_ENTRIES = "entries"
const ID_HABIT_ZONE = "habit_zone"
const ID_VIEWS = "views"
const ID_360_VR = "360_views"
const ID_ANIMATIONS = "animations"

const ID_TYPES = "types/"
const ID_TYPES_BURIAL_DIR_MONUMENTAL = "types/dir_monumental"
const ID_TYPES_BURIAL_MONUMENTAL = "types/monumental"
const ID_TYPES_LAWN_GRAVE_HEADSTONES = "types/lawn_grave_headstones"
const ID_TYPES_LAWN_GRAVE_PLAQUES = "types/lawn_grave_plaques"
const ID_TYPES_MEMORIALS = "types/memorials"

const ID_ZOOM_IN = "zoom in"
const ID_ZOOM_OUT = "zoom out"



function HotspotsInfo(){
    return (
        <HotSpots>
            <HotspotItem type="INFO" title="" tmbSrc={require("../assets/images/masterplan/burial-snowgum-tmb.jpg").default} tmbXPos={800} tmbYPos={1045} src={require("../assets/images/masterplan/burial-snowgum.jpg").default} />
            <HotspotItem type="INFO" title="" tmbSrc={require("../assets/images/masterplan/burial-sugargum-tmb.jpg").default} tmbXPos={480} tmbYPos={965} src={require("../assets/images/masterplan/burial-sugargum.jpg").default} />
            <HotspotItem type="INFO" title="" tmbSrc={require("../assets/images/masterplan/burial-ironbark-tmb.jpg").default} tmbXPos={485} tmbYPos={1390} src={require("../assets/images/masterplan/burial-ironbark.jpg").default} />
        </HotSpots>
    )
}

function HotspotsImages(){
    return (
        <HotSpots>
            <HotspotItem type="IMAGE" title="River Red Gum Precinct - northern aspect" tmbSrc={require('../assets/images/gallery/V01-tmb.jpg').default} tmbXPos={2583} tmbYPos={1625} src={require('../assets/images/gallery/V01.jpg').default} flipX={true}/>
            <HotspotItem type="IMAGE" title="River Red Gum Precinct – southern aspect" tmbSrc={require('../assets/images/gallery/V02-tmb.jpg').default} tmbXPos={2112} tmbYPos={240} src={require('../assets/images/gallery/V02.jpg').default} flipX={true}/>
            <HotspotItem type="IMAGE" title="Sugar Gum - tradition in natural surrounds" tmbSrc={require('../assets/images/gallery/V03-tmb.jpg').default} tmbXPos={309} tmbYPos={905} src={require('../assets/images/gallery/V03.jpg').default} />
            <HotspotItem type="IMAGE" title="Snow Gum monumental area - a place to honour and reflect" tmbSrc={require('../assets/images/gallery/V04-tmb.jpg').default} tmbXPos={705} tmbYPos={1192} src={require('../assets/images/gallery/V04.jpg').default} />
            <HotspotItem type="IMAGE" title="The morning light over the Snow Gum monumental area" tmbSrc={require('../assets/images/gallery/V05-tmb.jpg').default} tmbXPos={930} tmbYPos={878} src={require('../assets/images/gallery/V05.jpg').default} />
            <HotspotItem type="IMAGE" title="View from the Memorial wall looking out over the length of the park" tmbSrc={require('../assets/images/gallery/V06-tmb.jpg').default} tmbXPos={2180} tmbYPos={1207} src={require('../assets/images/gallery/V06.jpg').default} />
        
            <HotspotItem type="IMAGE" title="New park entry" tmbSrc={require('../assets/images/gallery/V11-tmb-large.jpg').default} tmbXPos={2134} tmbYPos={1567} src={require('../assets/images/gallery/V11.jpg').default} />
            <HotspotItem type="IMAGE" title="Memorial Wall looking to park entry" tmbSrc={require('../assets/images/gallery/V08-tmb-large.jpg').default} tmbXPos={2215} tmbYPos={1287} src={require('../assets/images/gallery/V08.jpg').default} />
            <HotspotItem type="IMAGE" title="Memorial Wall looking to Lawn Burials" tmbSrc={require('../assets/images/gallery/V09-tmb-large.jpg').default} tmbXPos={2101} tmbYPos={1133} src={require('../assets/images/gallery/V09.jpg').default} />
            <HotspotItem type="IMAGE" title="Memorial Wall looking to Habitat Zone" tmbSrc={require('../assets/images/gallery/V10-tmb-large.jpg').default} tmbXPos={2180} tmbYPos={1133} src={require('../assets/images/gallery/V10.jpg').default} />
            <HotspotItem type="IMAGE" title="Snow Gum monumental area - a place to honour and reflect" tmbSrc={require('../assets/images/gallery/V12-tmb-large.jpg').default} tmbXPos={657} tmbYPos={788} src={require('../assets/images/gallery/V12.jpg').default} />
            <HotspotItem type="IMAGE" title="Lake vista to Memorial wall" tmbSrc={require('../assets/images/masterplan/Photos/photo-3534-tmb.jpg').default} tmbXPos={1744} tmbYPos={1419} src={require('../assets/images/gallery/Photos/photo-3534.jpg').default} />
            <HotspotItem type="IMAGE" title="Casuarina Lane" tmbSrc={require('../assets/images/masterplan/Photos/photo-3500-tmb.jpg').default} tmbXPos={1698} tmbYPos={697} src={require('../assets/images/gallery/Photos/photo-3500.jpg').default} />
            <HotspotItem type="IMAGE" title="Memorial wall" tmbSrc={require('../assets/images/masterplan/Photos/photo-3605-tmb.jpg').default} tmbXPos={2100} tmbYPos={1210} src={require('../assets/images/gallery/Photos/photo-3605.jpg').default} />
            <HotspotItem type="IMAGE" title="Memorial wall" tmbSrc={require('../assets/images/masterplan/Photos/photo-3481-tmb.jpg').default} tmbXPos={2261} tmbYPos={1210} src={require('../assets/images/gallery/Photos/photo-3481.jpg').default} />
            <HotspotItem type="IMAGE" title="Memorial wall" tmbSrc={require('../assets/images/masterplan/Photos/photo-3572-tmb.jpg').default} tmbXPos={2007} tmbYPos={1210} src={require('../assets/images/gallery/Photos/photo-3572.jpg').default} />
        </HotSpots>
    );
}


function HotspotsVR(){
    return (
        <HotSpots>
            <HotspotItem title="Sugar Gum area" type="SKY_BOX" tmbSrc={require("../assets/images/gallery/VR01-tmb.jpg").default} tmbXPos={152} tmbYPos={904} src={`VR01_V03`}  />
            <HotspotItem title="Snow Gum area" type="SKY_BOX" tmbSrc={require("../assets/images/gallery/VR02-tmb.jpg").default} tmbXPos={792} tmbYPos={800} src={`VR02_V04`} />
            <HotspotItem title="Snow Gum area" type="SKY_BOX" tmbSrc={require("../assets/images/gallery/VR03-tmb.jpg").default} tmbXPos={1200} tmbYPos={992} src={`VR03_V05`} />
            <HotspotItem title="Memorial wall" type="SKY_BOX" tmbSrc={require("../assets/images/gallery/VR04-tmb.jpg").default} tmbXPos={2160} tmbYPos={1200} src={`VR04_V06`} />
        </HotSpots>
    )
}


function HotspotsVideo(){
    return (
        <HotSpots>
            <HotspotItem title="River Red Gum Precinct approach" type="VIDEO" tmbSrc={require("../assets/images/gallery/A01-tmb.jpg").default} tmbXPos={2595} tmbYPos={1717} src={require('../assets/videos/A01.mp4').default} flipX={true}/>
            <HotspotItem title="River Red Gum Precinct – southern aspect" type="VIDEO" tmbSrc={require("../assets/images/gallery/A02-tmb.jpg").default} tmbXPos={1546} tmbYPos={363} src={require('../assets/videos/A02.mp4').default} />
            <HotspotItem title="Habitat zone" type="VIDEO" tmbSrc={require("../assets/images/gallery/A03-tmb.jpg").default} tmbXPos={1980} tmbYPos={703} src={require('../assets/videos/A03.mp4').default} />
            <HotspotItem title="Memorial wall" type="VIDEO" tmbSrc={require("../assets/images/gallery/A05-tmb.jpg").default} tmbXPos={2303} tmbYPos={1333} src={require('../assets/videos/A05.mp4').default} />
            <HotspotItem title="Snow Gum area" type="VIDEO" tmbSrc={require("../assets/images/gallery/A07-tmb.jpg").default} tmbXPos={819} tmbYPos={941} src={require('../assets/videos/A07.mp4').default} />
            <HotspotItem title="Sugar Gum area" type="VIDEO" tmbSrc={require("../assets/images/gallery/A09-tmb.jpg").default} tmbXPos={483} tmbYPos={980} src={require('../assets/videos/A09.mp4').default} />
            <HotspotItem title="Sugar Gum area" type="VIDEO" tmbSrc={require("../assets/images/gallery/A08-tmb.jpg").default} tmbXPos={351} tmbYPos={1069} src={require('../assets/videos/A08.mp4').default}/>
            <HotspotItem title="Connection from Northern Memorial Park" type="VIDEO" tmbSrc={require("../assets/images/gallery/A04-tmb.jpg").default} tmbXPos={2215} tmbYPos={1461} src={require('../assets/videos/A04.mp4').default} />
        </HotSpots>
    )
}


export function Masterplan(){
    const [selection, setSelection] = useState("");
    const [pos, setPos] = useState({x:0, y:0});
    const [scale, setScale] = useState(1);
    
    const zoomIn = (value:number) => {
        if(scale>1 || scale<3) setScale(scale + value);
    }
    

    const style={
        backgroundColor: 'var(--color-white)'
    }
    
    return (
        <div style={style}>
            <FullView />
            <Page title="Masterplan" backgroundColor="#FFFFFF" titleColor="#525252">
                <LoadingIndicator>
                    <ZoomPinch pos={pos} setPos={setPos} scale={scale} setScale={setScale}>
                        <LayerContainer>
                            <Layer src={require('../assets/images/masterplan/bkg--masterplan.jpg').default}/>
                            <LayerSwitch selection={selection} layers={{
                                [ID_TRAIN]                   : <Layer src={require('../assets/images/masterplan/frg--train.jpg').default} />,
                                [ID_BICYLE]                  : <Layer src={require('../assets/images/masterplan/frg--bicycle.jpg').default} />,
                                [ID_ENTRIES]                 : <Layer src={require('../assets/images/masterplan/frg--entries.jpg').default} />,
                                [ID_HABIT_ZONE]              : <Layer src={require('../assets/images/masterplan/frg--habitat-zone.jpg').default} />,
                                [ID_BURRIAL_INFO]            : <Layer src={require('../assets/images/masterplan/frg--burial_info.jpg').default}><HotspotsInfo /></Layer>,
                                [ID_VIEWS]                   : <HotspotsImages />,
                                [ID_360_VR]                  : <HotspotsVR />,
                                [ID_ANIMATIONS]              : <HotspotsVideo />,

                                [ID_TYPES_BURIAL_DIR_MONUMENTAL]   : <Layer src={require('../assets/images/masterplan/frg--directional-monumental.jpg').default} />,
                                [ID_TYPES_BURIAL_MONUMENTAL]       : <Layer src={require('../assets/images/masterplan/frg--monumental.jpg').default} />,
                                [ID_TYPES_LAWN_GRAVE_HEADSTONES]   : <Layer src={require('../assets/images/masterplan/frg--headstone.jpg').default} />,
                                [ID_TYPES_LAWN_GRAVE_PLAQUES]      : <Layer src={require('../assets/images/masterplan/frg--lawn.jpg').default} />,
                                [ID_TYPES_MEMORIALS]               : <Layer src={require('../assets/images/masterplan/frg--memorials.jpg').default} />,
                            }}/>
                        </LayerContainer>
                    </ZoomPinch>
                </LoadingIndicator>
                <BottomBar padding={30}>
                    <BarDropdown id={ID_TYPES} labelText="Types of burials" selection={selection} onClick={setSelection} onUnselected={(selection)=>setSelection("")}>
                        <BarDropdownItem id={ID_TYPES_BURIAL_DIR_MONUMENTAL} label="Directional Monumental" color="#E8CF5C" onClick={setSelection} selection={selection} />
                        <BarDropdownItem id={ID_TYPES_BURIAL_MONUMENTAL} label="Monumental" color="#B273B2" onClick={setSelection} selection={selection} />
                        <BarDropdownItem id={ID_TYPES_LAWN_GRAVE_HEADSTONES} label="Lawn Graves - headstones" color="#759EDB" onClick={setSelection} selection={selection} />
                        <BarDropdownItem id={ID_TYPES_LAWN_GRAVE_PLAQUES} label="Lawn Graves - plaques" color="#82DBBA" onClick={setSelection} selection={selection} />
                        <BarDropdownItem id={ID_TYPES_MEMORIALS} label="Memorials" color="#F5736B" onClick={setSelection} selection={selection} />
                    </BarDropdown>
                    <BarButton id={ID_BURRIAL_INFO} labelText="Burial Info" selection={selection} on={require('../assets/images/masterplan/icon--burial_info.png').default} off={require('../assets/images/masterplan/icon--burial_info-active.png').default} onClick={setSelection} />
                    <BarButton id={ID_TRAIN} labelText="Train" selection={selection} on={require('../assets/images/masterplan/icon--train.png').default} off={require('../assets/images/masterplan/icon--train-active.png').default} onClick={setSelection} />
                    <BarButton id={ID_BICYLE} labelText="Bicycle" selection={selection} on={require('../assets/images/masterplan/icon--bicycle.png').default} off={require('../assets/images/masterplan/icon--bicycle-active.png').default} onClick={setSelection} />
                    <BarButton id={ID_ENTRIES} labelText="Entries" selection={selection} on={require('../assets/images/masterplan/icon--entries.png').default} off={require('../assets/images/masterplan/icon--entries-active.png').default} onClick={setSelection} />
                    <BarButton id={ID_HABIT_ZONE} labelText="Habitat Zone" selection={selection} on={require('../assets/images/masterplan/icon--habitat.png').default} off={require('../assets/images/masterplan/icon--habitat-active.png').default} onClick={setSelection} />
                    <BarButton id={ID_VIEWS} labelText="Views" selection={selection} on={require('../assets/images/masterplan/icon--views.png').default} off={require('../assets/images/masterplan/icon--views-active.png').default} onClick={setSelection} />
                    <BarButton id={ID_360_VR} labelText="360 VR" selection={selection} on={require('../assets/images/masterplan/icon--vr.png').default} off={require('../assets/images/masterplan/icon--vr-active.png').default} onClick={setSelection} />
                    <BarButton id={ID_ANIMATIONS} labelText="Animations" selection={selection} on={require('../assets/images/masterplan/icon--animations.png').default} off={require('../assets/images/masterplan/icon--animations-active.png').default} onClick={setSelection} />
                    <BarBreak />
                    <BarLabel text="Zoom" selected={false}/>
                    <BarButton id={ID_ZOOM_IN} disabled={scale>=2} selection={selection} on={require('../assets/images/interface/icon--zoom-in.png').default} off={require('../assets/images/interface/icon--zoom-in.png').default} onClick={()=>zoomIn(0.6)} />
                    <BarButton id={ID_ZOOM_OUT} disabled={scale<=1} selection={selection} on={require('../assets/images/interface/icon--zoom-out.png').default} off={require('../assets/images/interface/icon--zoom-out.png').default} onClick={()=>zoomIn(-0.6)} />
                </BottomBar>
            </Page>
        </div>
    )
}