import { CSSProperties } from "react";
import { ReactNode } from "react";

export function BarLabel(props:{text:ReactNode, selected:boolean}){
    const {text, selected} = props;
    const style:CSSProperties = {
        color: selected ? 'var(--color-orange)' : 'var(--color-white)',
        fontSize: 28,
        fontFamily: "AppFontBold",
        marginLeft: 20,
        marginRight: 20,
        height: 60,
        display: 'flex',
        alignItems: 'center',
    }
    return <div style={style}>{text}</div>
}