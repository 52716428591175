import React, { CSSProperties, Dispatch, useRef } from "react"
import { useHover } from 'usehooks-ts'



export type MessageModalState = typeof messageModalInitialState; 
export type MessageModalAction
    = { id:"messageModal.show", message:{title?:React.ReactNode, body:React.ReactNode}}
    | { id:"messageModal.hide" }


export const messageModalInitialState = {
    message:null as null|{
        title?:React.ReactNode,
        body:React.ReactNode,
    }
}


export function messageModalReducer(state:MessageModalState, action:MessageModalAction):MessageModalState{
    switch(action.id){
        case "messageModal.show": return {...state, message:action.message};
        case "messageModal.hide": return {...state, message:null};
    }
}





export default ({state, dispatch}:{state:MessageModalState, dispatch:Dispatch<MessageModalAction>}) => {
    if(state.message==null) return null;

    function close(){
        dispatch({
            id:'messageModal.hide'
        })
    }

    return (
        <Container>
            <CloseButton onClick={close}/>
            <Panel>
                <Title title={state.message.title} />
                <Body body={state.message.body} />
            </Panel>
        </Container>
    )
}





function Container(props:{children:React.ReactNode}){
    const { children } = props;

    const style:CSSProperties = {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'green',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
    return (
        <div className="message-modal" style={style}>
            {children}
        </div>
    )
}




function CloseButton(props:{onClick:()=>void}){
    const { onClick } = props;
    const ref = useRef(null);
    const isHover = useHover(ref);

    const style:CSSProperties = {
        width: '60px',
        height: '60px',
        border: '2px solid red',
        position: 'absolute',
        top: '0px',
        right: '0px',
        margin: '20px',
        cursor: 'pointer',
    }

    const styleImg:CSSProperties = {
        width: '100%',
        height: '100%',
        opacity: isHover ? 0.5 : 1,
    }

    return (
        <div className="message-modal-close" style={style} ref={ref} onClick={onClick}>
            <img style={styleImg} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAV1BMVEX///8jHyAAAAAJAAALAAMNBAdoZmdiYGFmZGRta2sFAABpZ2hwbm8YExRbWFlhXl+dnJweGhsTDQ/T09O0s7M2MzNMSUqHhYbFxMWWlJQXEhNWVFRzcnIz5JTBAAAEQklEQVR4nO2d63aqMBCFBUVEpFatWtu+/3MeKeUUJbsEGEgya3+/XbMyzhWSDIsFIYQQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIEeR026X59X0i6e/XLN3dThNJt+F0iZOPojjG57cJpL+d42NRfCTxxZmOr3ERVRzijbj0dXz4kV7Er+LSrXiNo19WL8LSX1YN6W5UPDUVjKJkLyp9nzxIj1046qWIplPxScGouAgKt+TJhKWKn2LCP5Nn4Q6MeGstQs6KzxYsZd+EZNuzK1qriJJURPSmrWD0sRMR3YfUoGGUSGRUgwXvgSjz5/UhM2kooeKLScGoyATW3I/r0bSQ8Y5qctE7x6vIqvvw3sqlIioCBaN4qt73D84H81pGdTcPnUyDw1ls3fa8ASOOsWIKFIziKVr7TtZoOcnQNhy5aLRai67cGnPWG27FFMqTbuvHL2lQLKIYlG7qe2Eszt8q9nfUDVTQmQVL2i1yvay+KuKolmvoB4EdtZ9rwSzq0kUrcP7rk27SJVJw/n60xR79+6vcWkYGFXQagzVYRdsqlvuZZH6Bjrq0iyGvXbQCJvqlTUbN/Vfwj2JtEYuZ7y5aAZN9Z+nHddB5mXhkPTAWcQzKv0UfCY7FvxwVlwmPYrBmSF2EMSi+SSACLhrIHpuQLFgCkwYoGrBMDHgymQmYNpamd4E7qKBnWbQJdLtVW0WsoKcuWgFTR8tR8y1S0L5hdwJM/9tHK+6QgktHL53swbHY3Fv5gr/yOAZr4KNQo/RDS/ubRZvA5f93wGBjsAbWuR9H/YIxGIQFS2DR+FYx6BisgUVjmy8ytN8RiotWwFg8ns37jqDv8RjoqGBPDrfn3gLTDVAwmCTzC+w7jQrOf9RCANiYtdkGFoM1sLC3FAzQRSssHTW0LNoEFvcHBYOMwZqs21FDtmBJp4r+Pw920eGoYbtoxRfqQ0u2ChS8OyrqRO9dalDNNgadD3NzlmsC1NtQfRyqz6Xq66FNTxN0LKrvS9U/W6h/PlT/jN/rPY1hf9F71L9rU/++dMg776Dqovp9C/V7T2vt+4fde8CwWw3DUdXv46s/iwELvZbzNLhMKDkTpf5cm10M1sBY9PdsIj5fao4tfATa04yq/oyw+nPe8MaFlrP6Q+9bYEf1LBbxRUsld2bU33tSf3dN/f3D8XdIPXdU9feAZe5ye9zd4KkK/ZaGJyo4LhqCMxWgJKczFaQsWOLlXAzZ2SYwZblzVPXzabBbyc8YctKG658TpX7Wl/55bWjm3tiI8Wfm3nrmuYnzvwrfzzz7cv6aaJp9OeH8UgezL00zaKWeAwyl38EMWv1zhPXPgm7N85Z9VH1yVCfzvPXPZH+cqy9frjyYq199G6GY+NsIhctvIyzK71tk+/WE37fI95nT71sQQgghhBBCCCGEEEIIIYQQQgghhBBCCCGEEEIIIYQo5B8vlDCBniO5qwAAAABJRU5ErkJggg==" />
        </div>
    )
}




function Panel(props:{ children:React.ReactNode }){
    const {children } = props;
    const style:CSSProperties = {
        backgroundColor: 'var(--color-blue)',
        padding: '12px',
        borderRadius: "10px",
    }
    return (
        <div className="message-modal-panel" style={style}>
            { children }
        </div>
    )
}




function Title(props:{title:React.ReactNode|null}){
    const {title} = props;
    const style:CSSProperties = {
    }
    return (
        <div className="message-modal-title">
            {title}
        </div>
    )
}




function Body(props:{body:React.ReactNode}){
    const {body} = props;
    const style:CSSProperties = {

    }

    return (
        <div className="message-modal-body" style={style}>
            {body}
        </div>
    )
}