import { ImageLoader } from '../components/ImageSuspended'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { LoopingBackgroundFade } from '../components/LoopingBackgroundFade'
import { Page } from '../components/Page'

const images = [
    require('../assets/images/home/bkg--home-1.jpg').default,
    require('../assets/images/home/bkg--home-2.jpg').default,
    require('../assets/images/home/bkg--home-1.jpg').default,
    require('../assets/images/home/bkg--home-2.jpg').default,
]

export function Home(){
    return (
        <Page title="Home">
            <LoadingIndicator>
                <LoopingBackgroundFade>
                    { images.map((src,index) => (
                        <ImageLoader src={src} key={index}>
                            <img src={src}/>
                        </ImageLoader>
                    ))}
                </LoopingBackgroundFade>
            </LoadingIndicator>
        </Page>
    )
}
