import { CSSProperties, Dispatch, ReactNode, useContext, useEffect } from "react"
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { appContext } from "../App";

const TRANSITION_TIME = 0.4;


export type MenuState = typeof menuStateInitial;
export type MenuAction
    = { id: "menu.show" }
    | { id: "menu.hide" }
    | { id: "menu.hideHud" }
    | { id: "menu.showHud" }


export const menuStateInitial = {
    show: false,
    showHud: true,
}


export function menuReducer(state:MenuState, action:MenuAction):MenuState {
    switch (action.id) {
        case "menu.show": return { ...state, show:true };
        case "menu.hide": return { ...state, show:false };
        case "menu.showHud": return { ...state, showHud:true };
        case "menu.hideHud": return { ...state, showHud:false };
    }
}


type Props = {
    state: MenuState,
    dispatch: Dispatch<MenuAction>,
    children: ReactNode,
}



export function Menu(props:Props){
    const { children } = props;
    const state = props.state;
    const [app, dispatch] = useContext(appContext);
    const routes = app.config.routes;
    const location = useLocation();

    useEffect(()=>{
        dispatch({
            id: 'menu.hide',
        })
    }, [location, dispatch])

    useEffect(() => {
        if(app.carousel.show) dispatch({id:"menu.hideHud"})
        else dispatch({id:"menu.showHud"})
    }, [app.carousel.show, dispatch]);

    const style:CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: app.menu.showHud ? 1.0 : 0.0,
        transition: 'opacity 0.25s',
        color: 'var(--color-white)',
        pointerEvents: (state.show&&state.showHud) ? 'all' : 'none',
        fontFamily: 'AppFontBold',
        zIndex: app.config.zIndex.menu,
    }

    return (
        <>
            <div className="menu" style={style}>
                <Header>
                    <Logo />
                    <Title />
                    <HamburgerButton />
                </Header>
                <Links>
                    <Link show={state.show} route={routes.home}>Home</Link>
                    <Link show={state.show} route={routes.movie}>Movie</Link>
                    <Link show={state.show} route={routes.location}>Location</Link>
                    <Link show={state.show} route={routes.masterplan}>Masterplan</Link>
                    <Link show={state.show} route={routes.gallery}>Gallery</Link>
                </Links>
            </div>
            <div className="content">
                { children }
            </div>
        </>
    )
}


function HamburgerButton(){
    const [app, dispatch] = useContext(appContext);
    const hideMenu = () => dispatch({id:'menu.hide'});
    const showMenu = () => dispatch({id:'menu.show'});
    const stopMovie = () => dispatch({id:'movie.stop'});
    const onClose = () => app.moviePlayer.playing ?  stopMovie() : hideMenu();
    
    const showClose = app.menu.show || app.moviePlayer.playing;

    return (
        <>
            <MenuButton onClick={showMenu} show={!showClose}/>
            <CloseButton onClick={onClose} show={showClose}/>
        </>
    )
}


function Logo(){
    const [app, dispatch] = useContext(appContext);
    const menu = app.menu;
    const location = useLocation();
    const showLight = menu.show || location.pathname === app.config.routes.home;

    const style:CSSProperties = {
        position: 'relative',
        top: 76,
        left: 100
    }

    function imageStyle(theme:'light'|'dark'):CSSProperties{
        return {
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: theme==='light' ? (showLight?1:0) : (showLight?0:1),
            transition: `opacity ${TRANSITION_TIME}s`,
        }
    }

    return (
        <div style={style}>
            <img src={require("../assets/images/interface/logo--gmct-charcoal.png").default} style={imageStyle('dark')}/>
            <img src={require("../assets/images/interface/logo--gmct.png").default} style={imageStyle('light')}/>
        </div>
    )
}


function Title(){
    const [app, dispatch] = useContext(appContext);
    const menu = app.menu;

    const style:CSSProperties = {
        textAlign: 'center',
        position: 'relative',
        top: 114,
        opacity: menu.show ? 1 : 0,
    }

    const styleTop:CSSProperties = {
        fontFamily: 'AppFontBold',
        fontSize: 84,

    }

    const styleBottom:CSSProperties = {
        fontFamily: 'AppFontLight',
        fontSize: 84,
    }


    return (
        <div className="menu-title" style={style}>
            <div style={styleTop}>Northern Memorial Park</div>
            <div style={styleBottom}>River Red Gum Precinct</div>
        </div>
    )
}


function MenuButton(props:{show:boolean, onClick:()=>void}){
    const {show, onClick} = props;
    /*
    function showMenu(){
        dispatch({
            id: 'menu.show'
        })
    }
    */
    const style:CSSProperties = {
        fontSize: '40px',
        position: 'absolute',
        top: '114px',
        right: '96px',
        display: 'flex',
        alignItems: 'center',
        cursor: show ? 'pointer' : 'auto',
        pointerEvents: show ? 'all' : 'none',
        borderRadius: 20,
        backgroundColor: 'var(--color-orange)',
        paddingLeft: 30,
        clipPath: 'inset(0px -14px 0px -10px)',
        opacity: show ? 1 : 0,
        transition: `opacity ${TRANSITION_TIME}s`,
    }
    const labelStyle:CSSProperties = {
        position: 'relative',
        transition: `top ${TRANSITION_TIME}s`,
        top: show ? 0 : -100,
    }
    const iconStyle:CSSProperties = {
        position: 'relative',
        transition: 'top 0.5s',
        top: show ? 0 : -200,
    }

    return (
        <div className="menu-open-button" style={style} onClick={onClick}>
            <div style={labelStyle}>Menu</div>
            <img style={iconStyle} src={require('../assets/images/interface/btn--hamburger.png').default} />
        </div>
    )
}


function CloseButton(props:{show:boolean, onClick:()=>void}){
    const {show, onClick} = props;

    /*
    function hideMenu(){
        console.log("HIDE");
        dispatch({
            id: 'menu.hide'
        })
    }
    */

    const style:CSSProperties = {
        fontSize: '40px',
        position: 'absolute',
        top: '114px',
        right: '96px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        pointerEvents: show ? 'all' : 'none',
        clipPath: 'inset(0px -14px 0px -10px)',
    }
    const labelStyle:CSSProperties = {
        position: 'relative',
        transition: 'top 0.5s',
        top: show ? 0 : 100,
    }
    const iconStyle:CSSProperties = {
        position: 'relative',
        transition: 'top 0.5s',
        top: show ? 0 : 200,
    }
    return (
        <div className="menu-close-button" style={style} onClick={onClick}>
            <div style={labelStyle}>Close</div>
            <img style={iconStyle} src={require('../assets/images/interface/btn--close.png').default} />
        </div>
    )
}



function Header(props:{children:ReactNode}){
    const { children } = props;
    const [app, dispatch] = useContext(appContext);

    const style:CSSProperties = {
        width: app.config.width,
        position: 'absolute',
        display: 'grid',
        gridTemplateColumns: '25% 50% 25%',
        zIndex: app.config.zIndex.menu+1,
    }

    return <div className="menu-header" style={style}>{children}</div>
}


function Links(props:{children:ReactNode}){
    const { children } = props;
    const [app, dispatch] = useContext(appContext);
    const menu = app.menu;

    const style:CSSProperties = {
        width: app.config.width,
        height: app.config.height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 40,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: app.config.zIndex.menu-1,
        opacity: menu.show ? 1 : 0,
        transition: `opacity ${TRANSITION_TIME}s`,
        //backgroundImage: `url(${require('../assets/images/templates/menu.png').default})`,
        backgroundImage: `url(${require('../assets/images/interface/bkg--menu.png').default})`,
    }
  
    
    return (
        <div className="menu-links" style={style}>
            {children}
        </div>
    )
}


function Link(props:{show:boolean, route:string, children:ReactNode}){
    const { show, route, children } = props;
    const [app, dispatch] = useContext(appContext);
    const history = useHistory();
    const location = useLocation();
    const isPath = location.pathname===route;
    const onClick = () => {
        console.log("Click: " + isPath)
        if(isPath){
            dispatch({ id:'menu.hide' })
        }else{
            show && history.push(route);
        }
    }

    const style:CSSProperties = {
        color: 'var(--color-white)',
        borderBottom: isPath ? '3px solid white' : 'none',
        fontSize: 100,
        fontFamily: 'AppFontLight',
        padding: 0,
        paddingTop: 10,
        paddingBottom: 6,
        marginTop: 10,
        marginBottom: 10,
        position: 'relative',
        top: -40,
        pointerEvents: show ? 'all' : 'none',
        display: 'block',
        cursor: show ? 'pointer' : "none",
    }
    return <div style={style} onClick={onClick}>{children}</div>
}