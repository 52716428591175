import { useContext } from "react";
import { Route, Switch } from "react-router";
import { appContext } from "../App";
import { Gallery } from "./Gallery";
import { Home } from "./Home";
import { Location } from "./Location";
import { Masterplan } from "./Masterplan";
import { Movie } from "./Movie";


export function Routes(){
    const [app] = useContext(appContext);
    const routes = app.config.routes;

    return (
        <Switch>
            <Route path={routes.gallery} component={Gallery}/>
            <Route path={routes.location} component={Location}/>
            <Route path={routes.masterplan} component={Masterplan}/>
            <Route path={routes.movie} component={Movie}/>
            <Route path={routes.home} component={Home}/>
        </Switch>
    )
}