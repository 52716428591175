import { CSSProperties } from "react";


export function GalleryImageItem(props:{src:string, index:number}){
    const {src} = props;
    const style:CSSProperties = {
        width: 'var(--width)',
        height: 'var(--height)',
    }
    return (
        <div style={style}>
            <img src={src} style={{width:'auto', height:'100%'}} />
        </div>
    )
}
