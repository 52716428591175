import React, { CSSProperties, Dispatch, ReactNode, useContext, useEffect, useState } from "react"
import { appContext } from "../App";
import { NextPrevButton } from "./NextPrevButton";
import { useGesture } from "@use-gesture/react";


export type CarouselState = typeof carouselStateInitial;
export type CarouselAction
    = { id: "carousel.show" }
    | { id: "carousel.hide" }
    | { id: "carousel.pick", index:number }
    | { id: "carousel.next" }
    | { id: "carousel.prev" }


export const carouselStateInitial = {
    show: false,
    index: 0,
    shouldTransition: false,
}


export function carouselReducer(state: CarouselState, action: CarouselAction): CarouselState {
    switch (action.id) {
        case "carousel.show": return { ...state, show: true };
        case "carousel.hide": return { ...state, show: false, shouldTransition:false };
        case "carousel.pick": return { ...state, index: action.index, show:true };
        case "carousel.next": return { ...state, index: state.index+1, shouldTransition:true  };
        case "carousel.prev": return { ...state, index: state.index-1, shouldTransition:true  };
    }
}


type Props = {
    items:{
        title:ReactNode,
        caption?:ReactNode,
        elem:ReactNode,
    }[]
}

export function Carousel (props:Props) {
    const { items } = props;
    const [app, dispatch] = useContext(appContext);
    const state = app.carousel;
    const hasNext = state.index<items.length-1;
    const hasPrev = state.index>0;

    const close = () => dispatch({ id: 'carousel.hide' })
    const next = () => dispatch({ id: 'carousel.next' })
    const prev = () => dispatch({ id: 'carousel.prev' })

    /*
    useEffect(()=> {
        return ()=>{
            dispatch({
                id: 'carousel.hide',
            })
        }
    })
    */

    /*
    const bind = useGesture({
        onDrag: ({ movement: [vx], last }) => {
            if(last){
                if(vx < 4) if(hasNext) dispatch({ id:'carousel.next'})
                if (vx > 4) if(hasPrev) dispatch({ id:'carousel.prev'})
            }
          }
    })
    */

    const style:CSSProperties = {
        opacity: state.show ? 1 : 0,
        display: state.show ? 'flex' : 'none',
        pointerEvents: state.show ? 'all' : 'none',
        transition: 'opacity 0.5s',
        position: 'absolute',
        width: 'var(--width)',
        height: 'var(--height)',
        backgroundColor: '#282828',
        zIndex: app.config.zIndex.carousel,
    }


    return (
        <div className="carousel" style={style} /*{...bind()}*/>
            <CarouselItemContainer offset={state.index*app.config.width} shouldTransition={state.shouldTransition}> { items.map(CarouselItem) } </CarouselItemContainer>
            <CarouselCloseButton onClick={close}/>
            <NextPrevBar onNext={next} onPrev={prev} hasNext={hasNext} hasPrev={hasPrev}/>
        </div>
    )   
}


function NextPrevBar(props:{hasNext:boolean, hasPrev:boolean, onNext:()=>void, onPrev:()=>void}){
    const {hasNext, hasPrev, onNext, onPrev} = props;
    const style:CSSProperties = {
        marginRight: 100,
        width: 'var(--width)',
        position: 'absolute',
        bottom: 0,
        height: 110,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }

    return (
        <div className="next-prev-bar" style={style}>
            <NextPrevButton visible={hasPrev} onClick={onPrev} label="Previous" srcPrev={require('../assets/images/interface/icon--previous.png').default}/>
            <NextPrevButton visible={hasNext} onClick={onNext} label="Next" srcNext={require('../assets/images/interface/icon--next.png').default}/>
        </div>
    )
}



function CarouselCloseButton(props:{onClick:()=>void}){
    const { onClick } = props;

    const style:CSSProperties = {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 0,
        cursor: 'pointer',
        filter: 'drop-shadow(0px 8px 6px rgba(0, 0, 0, 0.3))',
    }
    return (
        <div className="carousel-close-button" style={style} onClick={onClick}>
            <img src={require('../assets/images/interface/btn--close.png').default} />
        </div>
    )
}


function CarouselItemContainer(props:{offset:number, shouldTransition:boolean, children:ReactNode}){
    const { offset, shouldTransition, children } = props;
    const itemsContainerStyle = {
        display: 'flex',
        transform: `translate(${-offset}px, 0)`,
        transition: shouldTransition ? 'transform 0.75s' : 'none',
    }
    
    return <div className="carousel-items-container" style={itemsContainerStyle}>{ children }</div>
}


function CarouselItem(item:Props['items'][number], index:number){
    const style:CSSProperties = {
        width: 'var(--width)',
        height: 'var(--height)',
        alignItems: 'center',
        //width: '100%',
    }

    return (
        <div className="carousel-item" style={style} key={index}>
            <CarouselItemElement>{ item.elem }</CarouselItemElement>
            <CarouselItemTitle>{ item.title }</CarouselItemTitle>
            { item.caption && <CarouselItemCaption>{ item.caption }</CarouselItemCaption> }
        </div>
    )
}


function CarouselItemTitle(props:{children:React.ReactNode}){
    const { children } = props;
    const style:CSSProperties = {
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'var(--color-white)',
        position: 'absolute',
        top: 2,
        width: 'var(--width)',
        fontFamily: 'AppFontLight',
        fontSize: 60,
    }
    return <div className="carousel-item-title" style={style}>{ children }</div>
}


function CarouselItemElement(props:{children:React.ReactNode}){
    const { children } = props;
    const style:CSSProperties = {
        width: '100%',
        height: '100%',
    }
    return <div className="carousel-item-element" style={style}>{ children }</div>
}


function CarouselItemCaption(props:{children:React.ReactNode}){
    const { children } = props;
    const style:CSSProperties = {
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 'var(--width)',
        position:  'absolute',
        color: 'var(--color-white)',
        bottom: 6,
        alignItems: 'center',
        fontSize: 40,
        fontFamily: 'AppFontLight',
    }
    return <div className="carousel-item-caption" style={style}>{children}</div>
}