import { useState } from "react";
import { CSSProperties, ReactNode, Suspense } from "react";
import { useTimeout } from "usehooks-ts";


export function LoadingIndicator(props:{children:ReactNode, height?:number, color?:string}){
    const {children, height, color} = props;
    return (
        <Suspense fallback={<Spinner height={height} color={color}/>}>
            {children}
        </Suspense>
    )
}

function Spinner(props:{height?:number, color?:string}){
    const {height, color} = props;
    const [show, setShow] = useState(false);

    useTimeout(()=>setShow(true), 2000)

    const style:CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: height || '100%',
        color: color || "#FFFFFF",
    }

    const loadingIndicatorStyle:CSSProperties = {
        fontSize: 50,
        fontFamily: "AppFontBold",
        opacity: show ? 0.4 : 0.0,
        transition: 'opacity 2s',
    }

    return (
        <div className="loading-indicator" style={style}>
            <div style={loadingIndicatorStyle}>Loading</div>
        </div>
    )
}

