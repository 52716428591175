import { CSSProperties } from 'react'
import { useContext } from 'react'
import { appContext } from '../App'
import { FullView } from '../components/FullView';
import { MoviePlayer } from '../components/MoviePlayer'
import { Page } from '../components/Page'

export function Movie(){
    const [app, dispatch] = useContext(appContext)

    const videoWidth = app.config.width;
    const videoScale = 1920 / app.config.width;
    const videoHeight = 1080 / videoScale;

    const styleContainer:CSSProperties = {
        width: '100%',
        height: '100%',
        display: 'flex',
    }

    const thumbStyle:CSSProperties = {
        width: '50%',
        height: 'auto',
        maxHeight: 'auto',
        alignSelf: "center",
        cursor: 'pointer',
    }

    const showMovie = (src:string, title:String) => () => {
        dispatch({
            id: "fullView.show",
            item: {
                type: "VIDEO",
                src,
                title
            }
        })
    }

    return (
        <div>
            <FullView />
            <Page title="Movie">
                <div style={styleContainer}>
                    <img onClick={showMovie(require('../assets/videos/movie.mp4').default, "Our vision")} style={thumbStyle} src={require('../assets/images/movie/movieA.png').default} />
                    <img onClick={showMovie(require('../assets/videos/movie_2.mp4').default, "Guided tour")} style={thumbStyle} src={require('../assets/images/movie/movieB.png').default} />
                </div>
            </Page>
        </div>
    )
}