import { CSSProperties } from "react";
import { ReactNode } from "react";

type Props = {
    id:string,
    onClick:(id:string)=>void,
    label:ReactNode,
    color:string,
    selection:string,
}


export function BarDropdownItem(props:Props){
    const {id, label, color, onClick, selection} = props;
    const selected = selection.indexOf(id) === 0;
    const handleClick = () => onClick(props.id)

    const style:CSSProperties = {
        marginTop: 4,
        marginBottom: 4,
        color: 'white',
        fontFamily: 'AppFontBold',
        fontSize: 30,
        display: 'flex',
        alignItems: 'center',
        height: 90,
        cursor: 'pointer',
        //backgroundColor: 'red',
    }
    
    const iconStyle:CSSProperties = {
        width: 50,
        minWidth: 50,
        height: 50,
        maxHeight: 50,
        borderRadius: '50%',
        backgroundColor: color,
        marginRight: 20,
    }

    const labelStyle:CSSProperties = {
        color: selected ? 'var(--color-orange)' : 'var(--color-white)',
    }

    return (
        <div className="bar-dropdown-item" style={style} onClick={handleClick}>
            <div style={iconStyle}/>
            <div style={labelStyle}>{label}</div>
        </div>
    )
}