import { CSSProperties, Suspense } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { ReactNode } from 'react'
import { appContext } from '../App';
import { BottomBar } from '../components/BottomBar';
import { Carousel } from '../components/Carousel';
import { GalleryImageItem } from '../components/GalleryImageItem';
import { GalleryMovieItem } from '../components/GalleryMovieItem';
import { ImageLoader } from '../components/ImageSuspended';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { MoviePlayer } from '../components/MoviePlayer';
import { NextPrevButton } from '../components/NextPrevButton';
import { Page } from '../components/Page'
import { VR } from '../components/VR';
import { GalleryVRItem } from '../components/GalleryVRItem';
import { title } from 'process';
import { useRef } from 'react';
import { useEffect } from 'react';

var ii = 0;
const inc = () => ii++;

const pageOneTop = [
    {
        title: "River Red Gum Precinct - northern aspect",
        thumb: require('../assets/images/gallery/V01-tmb.jpg').default,
        elem: <GalleryImageItem src={require('../assets/images/gallery/V01.jpg').default} index={inc()}/>,
    },{
        title: "River Red Gum Precinct – southern aspect",
        thumb: require('../assets/images/gallery/V02-tmb.jpg').default,
        elem: <GalleryImageItem src={require('../assets/images/gallery/V02.jpg').default} index={inc()}/>,
    },{
        title: "Sugar Gum - tradition in natural surrounds",
        thumb: require('../assets/images/gallery/V03-tmb.jpg').default,
        elem: <GalleryImageItem src={require('../assets/images/gallery/V03.jpg').default} index={inc()}/>,
    },{
        title: "Snow Gum monumental area - a place to honour and reflect",
        thumb: require('../assets/images/gallery/V04-tmb.jpg').default,
        elem: <GalleryImageItem src={require('../assets/images/gallery/V04.jpg').default} index={inc()}/>,
    },{
        title: "The morning light over the Snow Gum monumental area",
        thumb: require('../assets/images/gallery/V05-tmb.jpg').default,
        elem: <GalleryImageItem src={require('../assets/images/gallery/V05.jpg').default} index={inc()}/>,
    },{
        title: "View from the Memorial wall looking out over the length of the park",
        thumb: require('../assets/images/gallery/V06-tmb.jpg').default,
        elem: <GalleryImageItem src={require('../assets/images/gallery/V06.jpg').default} index={inc()}/>,
    }
]


const pageOneBottom = [
    {
        title: "Sugar Gum area",
        thumb: require('../assets/images/gallery/VR01-tmb.jpg').default,
        elem: <GalleryVRItem dir="VR01_V03" index={inc()}/>,
    },{
        title: "Snow Gum area",
        thumb: require('../assets/images/gallery/VR02-tmb.jpg').default,
        elem: <GalleryVRItem dir="VR02_V04" index={inc()}/>,
    },{
        title: "Snow Gum area",
        thumb: require('../assets/images/gallery/VR03-tmb.jpg').default,
        elem: <GalleryVRItem dir="VR03_V05" index={inc()}/>,
    },{
        title: "Memorial wall",
        thumb: require('../assets/images/gallery/VR04-tmb.jpg').default,
        elem: <GalleryVRItem dir="VR04_V06" index={inc()}/>,
    }
]


const pageTwo = [
    {
        title: "Proposed master plan",
        thumb: require("../assets/images/gallery/V07-tmb.jpg").default,
        elem: <GalleryImageItem src={require("../assets/images/gallery/V07.jpg").default} index={inc()}/>
    },{
        title: "Snow Gum monumental area - a place to honour and reflect",
        thumb: require("../assets/images/gallery/V12-tmb.jpg").default,
        elem: <GalleryImageItem src={require("../assets/images/gallery/V12.jpg").default} index={inc()}/>
    },{
        title: "New park entry",
        thumb: require("../assets/images/gallery/V11-tmb.jpg").default,
        elem: <GalleryImageItem src={require("../assets/images/gallery/V11.jpg").default} index={inc()}/>
    },{
        title: "Memorial Wall looking to park entry",
        thumb: require("../assets/images/gallery/V08-tmb.jpg").default,
        elem: <GalleryImageItem src={require("../assets/images/gallery/V08.jpg").default} index={inc()}/>
    },{
        title: "Memorial Wall looking to Lawn Burials",
        thumb: require("../assets/images/gallery/V09-tmb.jpg").default,
        elem: <GalleryImageItem src={require("../assets/images/gallery/V09.jpg").default} index={inc()}/>
    },{
        title: "Memorial Wall looking to Habitat Zone",
        thumb: require("../assets/images/gallery/V10-tmb.jpg").default,
        elem: <GalleryImageItem src={require("../assets/images/gallery/V10.jpg").default} index={inc()}/>
    },{
        title: "Lake vista to Memorial wall",
        thumb: require("../assets/images/gallery/Photos/photo-3534-tmb.jpg").default,
        elem: <GalleryImageItem src={require("../assets/images/gallery/Photos/photo-3534.jpg" ).default} index={inc()} />
    },{
        title: "Memorial wall",
        thumb: require("../assets/images/gallery/Photos/photo-3579-tmb.jpg").default,
        elem: <GalleryImageItem src={require("../assets/images/gallery/Photos/photo-3579.jpg").default} index={inc()}/>
    },{
        title: "Memorial wall",
        thumb: require("../assets/images/gallery/Photos/photo-3572-tmb.jpg").default,
        elem: <GalleryImageItem src={require("../assets/images/gallery/Photos/photo-3572.jpg").default} index={inc()}/>
    },{
        title: "Memorial wall",
        thumb: require("../assets/images/gallery/Photos/photo-3481-tmb.jpg").default,
        elem: <GalleryImageItem src={require("../assets/images/gallery/Photos/photo-3481.jpg").default} index={inc()}/>
    },{
        title: "Memorial wall",
        thumb: require("../assets/images/gallery/Photos/photo-3605-tmb.jpg").default,
        elem: <GalleryImageItem src={require("../assets/images/gallery/Photos/photo-3605.jpg").default} index={inc()}/>
    },{
        title: "Memorial wall",
        thumb: require("../assets/images/gallery/Photos/photo-3453-tmb.jpg").default,
        elem: <GalleryImageItem src={require("../assets/images/gallery/Photos/photo-3453.jpg").default} index={inc()}/>
    }, 
]


const pageThree = [
    {
        title:"Memorial wall",
        thumb: require("../assets/images/gallery/Photos/photo-3470-tmb.jpg").default,
        elem: <GalleryImageItem src={require("../assets/images/gallery/Photos/photo-3470.jpg").default} index={inc()} />
    },{
        title:"Memorial wall",
        thumb: require("../assets/images/gallery/Photos/photo-3471-tmb.jpg").default,
        elem: <GalleryImageItem src={require("../assets/images/gallery/Photos/photo-3471.jpg").default} index={inc()} />
    },{
        title:"Casuarina Lane",
        thumb: require("../assets/images/gallery/Photos/photo-3500-tmb.jpg").default,
        elem: <GalleryImageItem src={require("../assets/images/gallery/Photos/photo-3500.jpg").default} index={inc()} />
    },{
        title: "River Red Gum Precinct approach",
        thumb: require('../assets/images/gallery/A01-tmb.jpg').default,
        elem: <GalleryMovieItem src={require('../assets/videos/A01.mp4').default} index={inc()} autoplay={false}/>,
    },{
        title: "River Red Gum Precinct – southern aspect",
        thumb: require('../assets/images/gallery/A02-tmb.jpg').default,
        elem: <GalleryMovieItem src={require('../assets/videos/A02.mp4').default} index={inc()} autoplay={false}/>,
    },{
        title: "Habitat zone",
        thumb: require('../assets/images/gallery/A03-tmb.jpg').default,
        elem: <GalleryMovieItem src={require('../assets/videos/A03.mp4').default} index={inc()} autoplay={false}/>,
    },{
        title: "Connection from Northern Memorial Park",
        thumb: require('../assets/images/gallery/A04-tmb.jpg').default,
        elem: <GalleryMovieItem src={require('../assets/videos/A04.mp4').default} index={inc()} autoplay={false}/>,
    },{
        title: "Memorial wall",
        thumb: require('../assets/images/gallery/A05-tmb.jpg').default,
        elem: <GalleryMovieItem src={require('../assets/videos/A05.mp4').default} index={inc()} autoplay={false}/>,
    },{
        title: "Snow Gum area",
        thumb: require('../assets/images/gallery/A07-tmb.jpg').default,
        elem: <GalleryMovieItem src={require('../assets/videos/A07.mp4').default} index={inc()} autoplay={false}/>,
    },{
        title: "Sugar Gum area",
        thumb: require('../assets/images/gallery/A08-tmb.jpg').default,
        elem: <GalleryMovieItem src={require('../assets/videos/A08.mp4').default} index={inc()} autoplay={false}/>,
    },{
        title: "Sugar Gum area",
        thumb: require('../assets/images/gallery/A09-tmb.jpg').default,
        elem: <GalleryMovieItem src={require('../assets/videos/A09.mp4').default} index={inc()} autoplay={false}/>,
    },
]

const all = [...pageOneTop, ...pageOneBottom, ...pageTwo, ...pageThree];

const pages = [
    <GalleryPage key="a">
        <div style={{paddingLeft:64, paddingRight:64, marginBottom:40 , display:'grid', gridTemplateColumns:'33.333% 33.333% 33.333%', gridTemplateRows:'auto auto', columnGap:'0px', rowGap:'50px'}} >
            { pageOneTop.map((item, index) => <Item title={item.title} thumbSrc={item.thumb} index={all.indexOf(item)} key={index} />) }
        </div>
        <div style={{paddingLeft:72, paddingRight:72, display:'grid', gridTemplateColumns:'25% 25% 25% 25%', gridTemplateRows:'auto', columnGap:'0px', rowGap:'30px'}}>
            { pageOneBottom.map((item, index) => <Item title={item.title} thumbSrc={item.thumb} index={all.indexOf(item)} key={index} />) }
        </div>
    </GalleryPage>,
    <GalleryPage key="b">
        <div style={{paddingLeft:64, paddingRight:64, marginBottom:40 , display:'grid', gridTemplateColumns:'25% 25% 25% 25%', gridTemplateRows:'auto auto auto', columnGap:'0px', rowGap:'40px'}}  key="c">
            { pageTwo.map((item, index) => <Item title={item.title} thumbSrc={item.thumb} index={all.indexOf(item)} key={index} />) }
        </div>
    </GalleryPage>,
    <GalleryPage key="c">
        <div style={{paddingLeft:64, paddingRight:64, marginBottom:40 , display:'grid', gridTemplateColumns:'25% 25% 25% 25%', gridTemplateRows:'auto auto auto', columnGap:'0px', rowGap:'40px'}}  key="d">
            { pageThree.map((item, index) => <Item title={item.title} thumbSrc={item.thumb} index={all.indexOf(item)} key={index} />) }
        </div>
    </GalleryPage>
];


export function Gallery(){
    const [app, dispatch] = useContext(appContext);
    const [index, setIndex] = useState(0);
    const hasNext = index < pages.length-1;
    const hasPrev = index > 0;
    const next = () => setIndex(index=>index+1);
    const prev = () => setIndex(index=>index-1);

    const style={
        backgroundColor: 'var(--color-white)'
    }

    return (
        <div style={style}>
            <Carousel items={all} />
            <Page title="Gallery" spacer={true} titleColor="#525252">
                <GalleryPages index={index}>
                    {pages}
                </GalleryPages>
                <BottomBar>
                    <NextPrevButton visible={hasPrev} onClick={prev} label="Previous" srcPrev={require('../assets/images/interface/icon--previous.png').default}/>
                    <NextPrevButton visible={hasNext} onClick={next} label="Next" srcNext={require('../assets/images/interface/icon--next.png').default}/>
                </BottomBar>
            </Page>
        </div>
    )
}


function NextPrevBar(props:{hasNext:boolean, hasPrev:boolean, onNext:()=>void, onPrev:()=>void}){
    const {hasNext, hasPrev, onNext, onPrev} = props;
    const [app] = useContext(appContext);
    const style:CSSProperties = {
        marginRight: 100,
        width: '100%',
        height: '244px',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
    }

    const barStyle:CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#525252',
        padding: 20,
        paddingTop: 30,
        paddingBottom: 30,
        borderRadius: 20,
        width: app.config.width-190,
        position: 'relative',
        top: -20,
    }

    return (
        <div className="next-prev-bar" style={style}>
            <div style={barStyle}>
                <NextPrevButton visible={hasPrev} onClick={onPrev} label="Previous" srcPrev={require('../assets/images/interface/icon--previous.png').default}/>
                <NextPrevButton visible={hasNext} onClick={onNext} label="Next" srcNext={require('../assets/images/interface/icon--next.png').default}/>
            </div>
        </div>
    )
}





function GalleryPages(props:{children:ReactNode, index:number}){
    const {index, children} = props;
    const [app] = useContext(appContext);
    const style:CSSProperties = {
        width: '100%',
        height: '100%',
        display: 'flex',
        transform: `translate(${-index*app.config.width}px, 0)`,
        transition: 'transform 0.75s',
    }
    return (
        <div className="gallery-pages" style={style}>
            {children}
        </div>
    )
}


function GalleryPage(props:{children:ReactNode}){
    const {children} = props;
    const [app] = useContext(appContext);
    const style:CSSProperties = {
        width: 'var(--width)',
        minWidth: 'var(--width)',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    }
    return (
        <div className="gallery-page" style={style}>
            {children}
        </div>
    )
}


function Item(props:{thumbSrc:string, index:number, title:string}){
    const {thumbSrc, index, title} = props;
    const [app, dispatch] = useContext(appContext);
    const [loaded, setLoaded] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);
    const pick = () => dispatch({ id:'carousel.pick', index});


    const style:CSSProperties = {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    }


    const containerStyle:CSSProperties = {
        position: 'relative',
    }

    const imgStyle:CSSProperties = {
        width: 'auto',
        height: 'auto',
    }

    const captionStyle:CSSProperties = {
        width: '100%',
        padding: '12px',
        paddingLeft: '24px',
        paddingRight: '24px',
        color: 'white',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#414042',
        zIndex: 1,
        fontFamily: 'AppFontLight',
        fontSize: 40,
        display: loaded ? 'block' : 'none',
    }

    function handleImageLoaded(){
        setLoaded(true);
    }
    
    return (
        <div style={style}>
            <div style={containerStyle} onClick={pick}>
                <img src={thumbSrc} style={imgStyle} ref={imgRef} onLoad={handleImageLoaded}/>
                <div style={captionStyle}>{title}</div>
            </div>
        </div>
    )
}
