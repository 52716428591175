import { useContext } from "react";
import { CSSProperties } from "react";
import { ReactNode } from "react";
import { appContext } from "../App";


export function BottomBar(props:{children:ReactNode, padding?:number}){
    const {children, padding} = props;
    const [app] = useContext(appContext);

    const style:CSSProperties = {
        width: '100%',
        height: '244px',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
    }

    const barStyle:CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#525252',
        paddingTop: 30,
        paddingBottom: 30,
        borderRadius: 20,
        width: app.config.width-190,
        position: 'relative',
        top: -20,
        paddingLeft: padding || 0,
        paddingRight: padding || 0,
    }

    return (
        <div className="bottom-bar" style={style}>
            <div style={barStyle}>
                { children }
            </div>
        </div>
    )
}