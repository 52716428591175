import * as React from 'react'
import { Children, ReactNode, useEffect, useState, useContext, CSSProperties } from 'react'
import { appContext } from '../App'

type Props = {
    children:ReactNode
}


export function LoopingBackgroundFade(props:Props){
    const { children } = props;
    const [index, setIndex] = useState(0);
    const count = Children.count(children);

    useEffect(() => {
        const interval = setInterval(() => {
            if(!document.hidden){
                setIndex(index=>index>1000 ? 0 : index+1);
            }
        }, 5000);
        return () => clearInterval(interval);
    }, []);


    const style:CSSProperties = {
        width:'100%',
        height:'100%',
        display: 'flex',
    }


    function itemStyle(i:number):CSSProperties{
        let current = index % count;
        let next = (index+1) % count;
        let prev = current-1;
        if(index===1) prev = -1;

        var opacity = 0.0;
        if(i===next) opacity = 0;
        if(i===prev) opacity = 1.0;
        if(i===current) opacity = 1.0;

        var zIndex = -1;
        if(i===current) zIndex = 0;
        if(i===prev) zIndex = -1;
        if(i===next) zIndex = -1;

        return {
            position:'absolute',
            opacity,
            zIndex,
            transition: 'opacity 0.5s',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
        }
    }

    return (
        <div className="looping-background" style={style}>
            { Children.map(children, (child, i) => (
                <div style={itemStyle(i)}>{child}</div>
            ))}
        </div>
    )
}


