import { CSSProperties } from "react";
import { useContext } from "react";
import { ReactNode } from "react";
import { appContext } from "../App";
import { useOnClickOutside } from 'usehooks-ts'
import { useRef } from "react";
import { useHover } from 'usehooks-ts'


type Props = {
    title:ReactNode,
    tmbSrc:string,
    tmbXPos:number,
    tmbYPos:number,
    src:string,
    flipX?:boolean,
    type: "SKY_BOX" | "IMAGE" | "VIDEO" | "INFO",
}


function Icon({type}:{type:Props["type"]}){
    const iconStyle:CSSProperties = {
        transform: 'translate(-50%, -50%) scale(0.4, 0.4)',
    }

    switch(type){
        case "SKY_BOX": return <img style={iconStyle} src={require('../assets/images/interface/icon--vr.png').default} />
        case "IMAGE": return <img style={iconStyle} src={require('../assets/images/interface/icon--image.png').default} />
        case "INFO": return <img style={iconStyle} src={require('../assets/images/interface/icon--burial_info.png').default} />
        case "VIDEO": return <img style={iconStyle} src={require('../assets/images/interface/icon--video.png').default} />
    }
}

export default function HotspotItem(props:Props){
    const {title, tmbSrc, tmbXPos, tmbYPos, src, type, flipX} = props;
    const [app, dispatch] = useContext(appContext);
    const isPicked = app.hotspots.picked === src;
    const somethingPicked = app.hotspots.picked != null;
    const ref = useRef<HTMLDivElement>(null)

    useOnClickOutside(ref, () => {
        if(isPicked) dispatch({ id:'hotspots.unpick' });
    })


    const isHover = useHover(ref);

    const style:CSSProperties = {
        position: 'absolute',
        left: tmbXPos+46,
        top: tmbYPos+46,
        width: 1,
        height: 1,
        display: 'flex',
        cursor: 'pointer',
        transition: 'filter 0.1s',
        zIndex: isPicked ? 10 : 0,
        border: '1px solid red',
    }

    const thumbStyle:CSSProperties = {
        position: 'absolute',
        height: 200,
        width: 'auto',
        top: -15,
        left: flipX ? 'auto' : 70,
        right: flipX ? 60 : 'auto',
        transform: 'translate(0, -50%)',
        border: '4px solid white',
        display: isPicked ? 'block' : 'none',
        pointerEvents: isPicked ? 'all' : 'none',
    }

    const iconStyle:CSSProperties = {
        filter: isHover ? 'brightness(0.95)' : undefined,
    }

    function onClick(){
        if(!isPicked) dispatch({ id:'hotspots.pick', picked:src })
        else dispatch({ id:'fullView.show', item:props})
    }

    return (
        <div className="hot-spot-item" style={style} onClick={onClick} ref={ref}>
            <img style={thumbStyle} src={tmbSrc} />
            <div style={iconStyle}>
                <Icon  type={type} />
            </div>
        </div>
    )
}

