import { CSSProperties, ReactNode, useContext } from "react"
import { appContext } from "../App";
import { BackgroundImage } from "./BackgroundImage";

type Props = {
    children:ReactNode,
    title?:ReactNode,
    titleColor?:string,
    spacer?:boolean,
    backgroundColor?:string,
    backgroundImage?:string,
}


export function Page({ title, spacer, children, backgroundColor, titleColor, backgroundImage }:Props){
    const [app, dispatch,] = useContext(appContext);
    const style = {
        backgroundColor,
    }

    return (
        <div className="page" style={style}>
            { spacer && <HeadingSpacer /> }
            <Content>{children}</Content>
            <Title title={title} titleColor={titleColor}/>
            { backgroundImage && <BackgroundImage src={backgroundImage}/>}
        </div>
    )
}


function HeadingSpacer(){
    const [app, dispatch] = useContext(appContext);
    const style = {
        height: app.config.headerHeight
    }
    return <div className="page-heading-spacer" style={style}></div>
}


function Content(props:{children:ReactNode}){
    const {children} = props;
    const [app, dispatch] = useContext(appContext);

    const style:CSSProperties = {
        display: 'flex',
        width: app.config.width,
        height: app.config.height,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
    }
    return <div className="page-content" style={style}>{children}</div>
}


function Title(props:{title?:ReactNode, titleColor?:string}){
    const {title, titleColor} = props;
    const [app, dispatch] = useContext(appContext);
    if(!title) return null;

    const style:CSSProperties = {
        width: app.config.width,
        height: app.config.headerHeight,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        fontFamily: 'AppFontLight',
        color: titleColor || 'var(--color-white)',
        fontSize: 84,
        position: 'absolute',
        top: -50,
        left: 0,
    }

    return <div className="page-title" style={style}>{title}</div>
}