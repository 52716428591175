import { ReactNode } from 'react'; 
import { CSSProperties } from "react";
import { ImageLoader } from "./ImageSuspended";


export function Layer(props:{src:string, children?:ReactNode}){
    const {src, children} = props;
    const style:CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 'var(--width)',
        height: 'var(--height)',
        pointerEvents: 'none',
    }
    return (
        <ImageLoader src={src}>
            <img src={src} style={style}/>
            <div>
                { children }
            </div>
        </ImageLoader>   
    )    
}

