import useRaf from '@rooks/use-raf';
import React, { MutableRefObject, useContext, useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { OrbitControls } from 'three-orbitcontrols-ts';
import { CubeTextureLoader } from 'three'
import { appContext } from '../App';

type Props = {
    dir:string,
    disabled?:boolean,
}

export function VR(props:Props){
    const {dir, disabled} = props;
    const [app, dispatch] = useContext(appContext);

    const ref:MutableRefObject<HTMLCanvasElement> = useRef(null!);
    const sceneRef:MutableRefObject<ReturnType<typeof createScene>> = useRef(null!);
    const [frame, setFrame] = useState(0);
    const canvas = ref.current
    const scene = sceneRef.current;
  
    useRaf(() => {
        if(scene==null){
            setFrame(frame+1);
        }
    }, true);


    useEffect(() => {
        if(scene){
            scene.setEnabled(!disabled);
        }
    }, [disabled, scene])

    useEffect(()=>{
        if(sceneRef.current){
        }else if(canvas){
            const textures = [
                `${process.env.PUBLIC_URL}/images/gallery/${dir}/negX.jpg`, 
                `${process.env.PUBLIC_URL}/images/gallery/${dir}/posX.jpg`,
                `${process.env.PUBLIC_URL}/images/gallery/${dir}/posY.jpg`, // ROTATE 180
                `${process.env.PUBLIC_URL}/images/gallery/${dir}/negY.jpg`, // ROTATE 180
                `${process.env.PUBLIC_URL}/images/gallery/${dir}/negZ.jpg`,
                `${process.env.PUBLIC_URL}/images/gallery/${dir}/posZ.jpg`,
            ]
            sceneRef.current = createScene(canvas, textures);
        }
    }, [frame, canvas, sceneRef, dir])


    return (
        <div key={dir}>
            <canvas ref={ref} width={app.config.width} height={app.config.height} />
        </div>
    )
}



function createScene(canvas:HTMLCanvasElement, textures:any[]){
    if(canvas==null) return null;
        
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
        55,
        canvas.width / canvas.height,
        45,
        30000
    );
    camera.position.set(0, 0, 1);
    const renderer = new THREE.WebGLRenderer({ antialias: true, canvas });

    renderer.setSize(canvas.width, canvas.height);

    const controls = new OrbitControls(camera, canvas);
    controls.enableDamping = true;
    controls.rotateSpeed *= -0.25;

    const loader = new CubeTextureLoader();
    const texture = loader.load(textures);

    scene.background = texture;

    var cleared = false;
    var enabled = true;

    animate();

    function animate() {
        if(cleared) return;
        if(enabled){
            controls.update();
            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        }
    }

    function destroy(){
        cleared = true;
        console.log("Destroyed");
    }

    function setEnabled(value:boolean){
        enabled = value;
        animate();
    }

    return {
        destroy,
        animate,
        setEnabled,
    }
}
