import { useState } from "react"
import { useContext } from "react";
import { CSSProperties } from "react"
import { useDrag, useHover } from "@use-gesture/react"
import { appContext } from "../App";
import { getScale } from "./ScaleContainer";

const centre = {
    x: 1233,
    y: 627,
}
/*
const constrainPosition = (ox:number, oy:number) => {
    var dx = centre.x - ox;
    var dy = centre.y - oy;
    var dist = Math.sqrt(dx*dx+dy*dy);
    var ang = Math.atan2(dy, dx);
    if(dist < 100) dist = 100;
    if(dist > 500) dist = 500;
    const result = {
        x: Math.cos(ang) * dist,
        y: Math.sin(ang) * dist,
    }
    return result;
}
*/

const getKMs = (x:number, y:number) => {
    const radius = getRadius(x, y);
    return Math.floor(radius / 78);
}

const getRadius = (ox:number, oy:number) => {
    var dx = ox-centre.x;
    var dy = oy-centre.y;
    return Math.sqrt(dx*dx+dy*dy);
}


export function RadialHandle(props:{offset:{x:number, y:number}, containerScale:number}){
    const {offset, containerScale} = props;
    const [pos, setPos] = useState({x:2225, y:758});
    const [app] = useContext(appContext);
    const angle = Math.atan2(pos.y-centre.y, pos.x-centre.x);

    const bind = useDrag(({ delta:[dx, dy], event, cancel }) => {
        const newPos = {
            x: pos.x + (dx / containerScale) / scale,
            y: pos.y + (dy / containerScale) / scale,
        }

        const radius = getRadius(newPos.x, newPos.y);
        if(newPos.x>2650 || newPos.x<100 || newPos.y<100 || newPos.y>1765){
        }else{
            setPos(newPos);
            event.preventDefault();
            if(event.stopImmediatePropagation) event.stopImmediatePropagation();
            if(event.stopPropagation) event.stopPropagation();
        }
        
    },{
        capture: false,
        //axis: "x",
        eventOptions:{
            capture: true,
        }
    })


    const handleCircleStyle:CSSProperties = {
        width: 120,
        height: 120,
        borderRadius: '50%',
        backgroundColor: '#414042',
        zIndex: 100,
        display: 'flex',
        fontSize: 36,
        textAlign: 'center',
        color: 'var(--color-white)',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'AppFontBold',
    }

    const circleStyle:CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: app.config.width,
        height: app.config.height,
        pointerEvents: 'none',
    }

    const handleStyle:CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 180,
        position:'fixed',
        left: `calc(${pos.x}px)`,
        top: `calc(${pos.y}px)`,
        transform: `translate(-100px, -70px) rotate(${angle}rad)`,
        cursor: 'pointer',
    }

    const handleTextStyle = {
        transform: `rotate(${-angle}rad)`,
    }

    const style:CSSProperties = {
        width: '300px',
        height: '300px',
        backgroundColor: 'rgba(0, 100, 0, 0.5)',
        border: '4px solid blue',
    }
    const scale = getScale(app.config.width, app.config.height);

    return (
        <div style={style}>
            <div style={circleStyle}>
                <svg viewBox={`0 0 ${app.config.width} ${app.config.height}`} xmlns="http://www.w3.org/2000/svg">
                    <circle cx={centre.x}
                            cy={centre.y}
                            fill="none"
                            stroke="#919091"
                            strokeWidth={4/containerScale}
                            r={2}/>
                </svg>
            </div>
            <div style={circleStyle}>
                <svg viewBox={`0 0 ${app.config.width} ${app.config.height}`} xmlns="http://www.w3.org/2000/svg">
                    <circle cx={centre.x}
                            cy={centre.y}
                            fill="none"
                            stroke="#414042"
                            strokeWidth={3/containerScale}
                            r={getRadius(pos.x, pos.y)}/>
                </svg>
            </div>
            <div style={handleStyle} {...bind()}>
                <img src={require('../assets/images/interface/icon--chevron-left.png').default} />
                <div style={handleCircleStyle}>
                    <div style={handleTextStyle}>{getKMs(pos.x, pos.y)+"km"}</div>
                </div>
                <img src={require('../assets/images/interface/icon--chevron-right.png').default} />
            </div>
        </div>
    )
}
