import {ReactNode} from 'react'

export function LayerContainer(props:{children:ReactNode}){
    const {children} = props;
    const style = {
        width: 'var(--width)',
        height: 'var(--height)',
    }
    return (
        <div style={style}>{children}</div>
    )
}