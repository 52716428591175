import React, { ReactNode, useState } from 'react';
import { CSSProperties } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { BarBreak } from '../components/BarBreak';
import { BarButton } from '../components/BarButton';
import { BarLabel } from '../components/BarLabel';
import { BottomBar } from '../components/BottomBar';
import { Layer } from '../components/Layer';
import { LayerContainer } from '../components/LayerContainer';
import { LayerSwitch } from '../components/LayerSwitch';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { Page } from '../components/Page'
import { RadialHandle } from '../components/RadialHandle';
import { ZoomPinch } from '../components/ZoomPinch';

const ID_TRAIN = "train";
const ID_BUS_TRAM = "bus_tram";
const ID_ROAD = "road";
const ID_ENTRIES = "entries";
const ID_ZOOM_IN = "zoom in";
const ID_ZOOM_OUT = "zoom out";



export function Location(){
    const [selection, setSelection] = useState("");
    const [pos, setPos] = useState({x:0, y:0});
    const [scale, setScale] = useState(1);

    const zoomIn = (value:number) => {
        if(scale>1 || scale<3) setScale(scale + value);
    }
    
    return (
        <Page title="Location" backgroundColor="#FFFFFF" titleColor="#525252">
            <LoadingIndicator>
                <ZoomPinch pos={pos} setPos={setPos} scale={scale} setScale={setScale}>
                    <TransformComponent>
                        <LayerContainer>
                            <Layer src={require('../assets/images/location/bkg--location.jpg').default}/>
                            <LayerSwitch selection={selection} layers={{
                                [ID_TRAIN]   : <Layer src={require('../assets/images/location/frg--train.jpg').default} />,
                                [ID_BUS_TRAM]: <Layer src={require('../assets/images/location/frg--bus-and-tram.jpg').default} />,
                                [ID_ROAD]    : <Layer src={require('../assets/images/location/frg--road.jpg').default} />,
                                [ID_ENTRIES] : <Layer src={require('../assets/images/location/frg--entries.jpg').default} />,
                            }}/>
                        </LayerContainer>
                        {/*<Template />*/}
                        <RadialHandle containerScale={scale} offset={{x:pos.x, y:pos.y}}/>
                    </TransformComponent>
                </ZoomPinch>
                <BottomBar padding={60}>
                    <BarButton id={ID_TRAIN} labelText="Train" selection={selection} on={require('../assets/images/location/icon--train.png').default} off={require('../assets/images/location/icon--train-active.png').default} onClick={setSelection} />
                    <BarButton id={ID_BUS_TRAM} labelText="Bus and Tram" selection={selection} on={require('../assets/images/location/icon--trambus.png').default} off={require('../assets/images/location/icon--trambus-active.png').default} onClick={setSelection} />
                    <BarButton id={ID_ROAD} labelText="Road" selection={selection} on={require('../assets/images/location/icon--road.png').default} off={require('../assets/images/location/icon--road-active.png').default} onClick={setSelection} />
                    <BarButton id={ID_ENTRIES} labelText="Road" selection={selection} on={require('../assets/images/location/icon--entries.png').default} off={require('../assets/images/location/icon--entries-active.png').default} onClick={setSelection} />
                    <BarBreak />
                    <BarLabel text="Zoom" selected={false}/>
                    <BarButton id={ID_ZOOM_IN} disabled={scale>=2} selection={selection} on={require('../assets/images/interface/icon--zoom-in.png').default} off={require('../assets/images/interface/icon--zoom-in.png').default} onClick={()=>zoomIn(1)} />
                    <BarButton id={ID_ZOOM_OUT} disabled={scale<=1} selection={selection} on={require('../assets/images/interface/icon--zoom-out.png').default} off={require('../assets/images/interface/icon--zoom-out.png').default} onClick={()=>zoomIn(-1)} />
                </BottomBar>
            </LoadingIndicator>
        </Page>
    )
}


function Template(){
    const style:CSSProperties = {
        position: 'absolute',
        top: -23,
        left: 0,
        opacity: 0.5,
    }

    return (
        <img style={style} src={require('../assets/images/templates/location-7.png').default} />
    )
}