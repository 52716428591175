let devTools:any = null;

function connect(){
    if(devTools) return
    const win:any = window;
    devTools = win.__REDUX_DEVTOOLS_EXTENSION__
    if(!devTools) return;
    
    let tools = devTools.connect()
}

function update(command:string, state:any){
    if(!devTools) connect();
    if(!devTools) return;
    devTools.send(command, state);
}


export default {
    update,
}