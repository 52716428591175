import { ReactNode } from "react";
import usePromise from 'react-promise-suspense';

const cache:Record<string, Promise<any>> = {}


function ImageResource(source:string){
    if(!cache[source]){
        cache[source] = new Promise((resolve) => {
            const img = new Image();
            img.src = source;
            img.onload = resolve;
            //img.onload = () => setTimeout(resolve, 4000);
        })  
    }
    return cache[source];
}


export function ImageLoader(props:{src:string, children:ReactNode}){
    const {src, children} = props;
    usePromise(ImageResource, [src]);
    return <>{children}</>;
}
