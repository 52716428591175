import { CSSProperties, ReactNode } from "react";
import { BarLabel } from "./BarLabel";
import { useOnClickOutside } from 'usehooks-ts';
import { useRef } from "react";
import { useState } from "react";


export function BarDropdown(props:{disabled?:boolean, id:string, selection:string, labelText?:ReactNode, children:ReactNode, onClick:(selection:string)=>void, onUnselected:(selection:string)=>void}){
    const {disabled, id, selection, labelText, onClick, onUnselected, children} = props;
    const [open, setOpen] = useState(false)
    const handleButtonClick = () => setOpen(!open);
    const ref = useRef(null)


    useOnClickOutside(ref, () => {
        if(open) setOpen(false)
    })

    const style:CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        marginRight: 20,
        cursor: 'pointer',
        pointerEvents: disabled ? 'none' : 'all',
        opacity: disabled ? 0.5 : 1.0,
    }

    const iconStyle:CSSProperties = {
        transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.25s'
    }

    const barPopupStyle:CSSProperties = {
        position: 'absolute',
        bottom: 130,
        left: 0,
        width: 355,
        backgroundColor: '#525252',
        borderRadius: 20,
        padding: 40,
       // pointerEvents: selected ? 'none' : 'all',
        opacity: open ? 1.0 : 0.0,
        transition: 'opacity 0.2s'
    }

    return (
        <div>
            <div className="bar-popup" style={barPopupStyle}>{children}</div>
            <div className="bar-button" onClick={handleButtonClick} style={style} ref={ref}>
                { labelText && <BarLabel text={labelText} selected={false} /> }
                <div style={iconStyle}><img src={require('../assets/images/interface/icon--previous__up.png').default}/></div>
            </div>
        </div>
    )
}
