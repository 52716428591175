import { useContext } from "react";
import { useEffect } from "react";
import { ReactNode } from "react";
import { appContext } from "../App";
import { Carousel } from "./Carousel";
import { GalleryImageItem } from "./GalleryImageItem";
import { GalleryMovieItem } from "./GalleryMovieItem";
import { GalleryVRItem } from "./GalleryVRItem";

type Item = {
    type: "SKY_BOX" | "IMAGE" | "VIDEO" | "INFO",
    src: string,
    title: ReactNode,
}

export const fullViewStateInitial = {
    item: null as null|Item,
}


export type FullViewState = typeof fullViewStateInitial;
export type FullViewAction
    = { id: "fullView.show", item:Item }
    | { id: "fullView.hide" }


export function fullViewReducer(state:FullViewState, action:FullViewAction):FullViewState {
    switch (action.id) {
        case "fullView.show": return { ...state, item:action.item };
        case "fullView.hide": return { ...state, item:null };
    }
}


export function FullView(){
    const [app, dispatch] = useContext(appContext);

    useEffect(()=>{
        if(app.carousel.show===false) dispatch({id:'fullView.hide'});
    }, [app.carousel.show, dispatch])
    
    return <Carousel items={ GalleryItem(app.fullview.item)}/>
}


function GalleryItem(item:Item|null){
    const [app, dispatch] = useContext(appContext);
 
    useEffect(()=> {
        if(item) dispatch({id:'carousel.pick', index:0});
        else dispatch({id:'carousel.hide'});
    }, [item, dispatch])

    if(!item) return [];

    const {title, type, src} = item;
    switch(type){
        case "SKY_BOX": return [{title, elem:<GalleryVRItem dir={src} index={0}/>}]
        case "IMAGE": return [{title, elem:<GalleryImageItem src={src} index={0}/>}]
        case "INFO": return [{title, elem:<GalleryImageItem src={src} index={0}/>}]
        case "VIDEO": return [{title, elem:<GalleryMovieItem src={src} index={0} autoplay={true}/>}]
    }
}
