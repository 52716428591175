import { animated } from "@react-spring/web";
import { useGesture } from "@use-gesture/react";
import { useContext } from "react";
import { useEffect } from "react";
import { ReactNode } from "react";
import { appContext } from "../App";
import { getScale } from "./ScaleContainer";


type Props = {
    pos: {x:number, y:number},
    setPos: (pos:{x:number, y:number})=>void,
    scale: number,
    setScale: (scale:number)=>void,
    children:ReactNode,
}

export function ZoomPinch(props:Props){
    const {pos, setPos, scale, setScale, children} = props;
    const [app] = useContext(appContext)
    const style = { x:pos.x, y:pos.y, scale }
    const appScale = getScale(app.config.width, app.config.height);

    function constrainPosition(pos:{x:number, y:number}){
        var xMin = ((app.config.width*scale) - app.config.width) / 2;
        var yMin = ((app.config.height*scale) - app.config.height) / 2;
        var xMax = ((app.config.width*scale) - app.config.width)*-0.5;
        var yMax = ((app.config.height*scale) - app.config.height)*-0.5;
        var newX = pos.x;
        var newY = pos.y;
        if(newX > xMin) newX = xMin;
        if(newX < xMax) newX = xMax;
        if(newY > yMin) newY = yMin;
        if(newY < yMax) newY = yMax;
        return {
            x:newX,
            y:newY,
        }
    }
    
    useEffect(() => {
        setPos(constrainPosition({
            x: pos.x,
            y: pos.y,
        }))
    }, [pos.x, pos.y, scale])
    
    const bind = useGesture({
        onDrag:({ delta: [mx, my] }) => {
            var newPos = {
                x: pos.x + (mx/appScale),
                y: pos.y + (my/appScale),
            };
            setPos(constrainPosition(newPos))
        },
        onPinch:({offset}) => {
            var newScale = offset[0];
            if(newScale < 1) newScale = 1;
            if(newScale > 3) newScale = 3;
            setScale(newScale);
        }
    })

    return <animated.div {...bind()} style={style}>{children}</animated.div>
}
