import * as React from 'react'
import { useReducer } from 'react';
import { Dispatch, useContext } from "react";
import { CSSProperties } from "react";
import { ReactNode } from "react";

export type HotspotsState = typeof hotspotsStateInitial;
export type HotspotsAction
    = { id: "hotspots.pick", picked:string }
    | { id: "hotspots.unpick" }


export const hotspotsStateInitial = {
    picked: null as string|null,
}


export function hotspotsReducer(state:HotspotsState, action:HotspotsAction):HotspotsState {
    switch (action.id) {
        case "hotspots.pick": return { ...state, picked:action.picked };
        case "hotspots.unpick": return { ...state, picked:null };
    }
}


type Props = {
    children:ReactNode,
}

export const hotspotsContext = React.createContext<[state:HotspotsState, dispatch: Dispatch<HotspotsAction>]>(null!);


export default function HotSpots(props:Props){
    const [state, dispatch] = useReducer(hotspotsReducer, hotspotsStateInitial);
    const {children} = props;
    
    const style:CSSProperties = {
        width: '100%',
        height: '100%',
    }
    return (
        <div className="hot-spots" style={style}>
            <hotspotsContext.Provider value={[state, dispatch]}>
                {children}
            </hotspotsContext.Provider>
        </div>
    )
}
