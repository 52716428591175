import { CSSProperties } from "react"

type Props = {
    src:string,
}


export function BackgroundImage({src}:Props){
    const style:CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
    }
    return <img src={src} style={style} />
}