import { useContext } from "react";
import { appContext } from "../App";
import { VR } from "./VR";


export function GalleryVRItem(props:{dir:string, index:number}){
    const {dir, index} = props;
    const [app] = useContext(appContext);
    return (
        <div>
            <VR dir={dir} disabled={index !== app.carousel.index}/>
        </div>
    )
}
