import React, { CSSProperties, Dispatch, useEffect, useReducer } from 'react';
import { HashRouter } from 'react-router-dom';
import { Carousel, CarouselAction, carouselReducer, carouselStateInitial } from './components/Carousel'
import { FullViewAction, fullViewReducer, FullViewState, fullViewStateInitial } from './components/FullView';
import { HotspotsAction, hotspotsReducer, hotspotsStateInitial } from './components/HotSpots';
import { Menu, MenuAction, menuReducer, menuStateInitial } from './components/Menu';
import MessageModal, { MessageModalAction, messageModalInitialState, messageModalReducer } from './components/MessageModal';
import { MoviePlayerAction, moviePlayerReducer, moviePlayerStateInitial } from './components/MoviePlayer';
import ScaleContainer from './components/ScaleContainer';
import { VR } from './components/VR';
import Config from './Config';
import DevTools from './DevTools';
import { Routes } from './routes/Routes';


type AppState = typeof initialAppState;
type AppAction = CarouselAction | MessageModalAction | MenuAction | MoviePlayerAction | HotspotsAction | FullViewAction;


const initialAppState = {
    carousel: carouselStateInitial,
    messageModal: messageModalInitialState,
    menu: menuStateInitial,
    moviePlayer: moviePlayerStateInitial,
    hotspots: hotspotsStateInitial,
    fullview: fullViewStateInitial,
    config: Config,
}


function reducer(state: AppState, action: AppAction): AppState {
    const result = {
        carousel: carouselReducer(state.carousel, action as CarouselAction) || state.carousel,
        messageModal: messageModalReducer(state.messageModal, action as MessageModalAction) || state.messageModal,
        menu: menuReducer(state.menu, action as MenuAction) || state.menu,
        moviePlayer: moviePlayerReducer(state.moviePlayer, action as MoviePlayerAction) || state.moviePlayer,
        hotspots: hotspotsReducer(state.hotspots, action as HotspotsAction) || state.hotspots,
        fullview: fullViewReducer(state.fullview, action as FullViewAction) || state.fullview,
        config: state.config,
    }
    DevTools.update(action.id, result);
    return result;
}


export const appContext = React.createContext<[state: AppState, dispatch: Dispatch<AppAction>]>(null!);


function App() {
    const [state, dispatch] = useReducer(reducer, initialAppState);

    /*
    useEffect(()=>{
      dispatch({
        id:'messageModal.show',
        message:{
          title:"Some title",
          body: "Here is the body"
        }
      })
    },[]);
    */

    function showModal() {
        dispatch({
            id: 'messageModal.show',
            message: {
                title: "Some title",
                body: "Here is the body"
            }
        })
    }

    function showCarousel(){
        console.log("Carousel show");
        dispatch({
            id: 'carousel.pick',
            index: Math.floor(Math.random() * 3),
        })
    }

    function showMenu(){
        dispatch({
            id: 'menu.show'
        })
    }

    const styleButton: CSSProperties = {
        backgroundColor: '#888888',
        width: '300px',
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px',
        cursor: 'pointer',
        position: 'fixed',
        bottom: 0,
        right: 0,
    }

    return (
        <div className="App">
            <appContext.Provider value={[state, dispatch]}>
                <ScaleContainer>
                    <HashRouter>
                        <MessageModal state={state.messageModal} dispatch={dispatch} />
                        <Menu state={state.menu} dispatch={dispatch}>
                            <Routes />
                        </Menu>
                    </HashRouter>
                </ScaleContainer>
            </appContext.Provider>
        </div>
    );
}

export default App;
